/* Start listing */
.OwnBusinessCon12 {
  align-self: stretch;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.OwnBusinessCon13 {
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.OwnBusinessCon15 {
  align-self: stretch;
  height: 15px;
  padding-left: 12px;
  padding-right: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

/* End Business */
/* Start Business Main */

.OwnBusinessConBusinessMessageCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  box-sizing: border-box;
}
.OwnBusinessConBusinessListingsCon {
  display: flex;
  padding: 6px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  background: var(--app-beige, #f0e6d2);
}

.OwnBusinessCon1 {
  width: 100%;
  height: 100%;
  /* background: var(--app-beige); */
  background: linear-gradient(
    180deg,
    #3a7d44 0%,
    rgba(58, 125, 68, 0.1) 13.49%,
    rgba(58, 125, 68, 0) 13.5%
  );
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  overflow: hidden;
  overflow-y: scroll;
  display: inline-flex;
  padding-bottom: 32px;
  box-sizing: border-box;
}

.OwnBusinessCon2 {
  display: flex;
  padding: 12px 12px 0px 12px;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
}

.OwnBusinessButtonCreateListing {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--app-gold-yellow, #f4c542);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #2b2b2b;
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.4px;
  word-wrap: break-word;
}

.OwnBusinessCon16 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.OwnBusinessCon17 {
  align-self: stretch;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.OwnBusinessCon18 {
  align-self: stretch;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  /* border-bottom: 1px solid var(--app-gray-medium, #6d6d6d); */
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 32px;
  display: inline-flex;
}
.OwnBusinessTitle2 {
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  flex: 1 0 0;
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}

.OwnBusinessButtonEditLists {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--app-orange, #ff7043);
}
.OwnBusinessButtonEditListsText {
  color: var(--app-white);
  font-size: 12px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.12px;
  word-wrap: break-word;
}

/* End Business Main */

.OwnBusinessCon3 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;
  display: inline-flex;
}

.OwnBusinessCon4B {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  box-sizing: border-box;
}
.OwnBusinessCon4 {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  border-radius: 5px;
  border: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-beige, #f0e6d2);
  box-shadow: 2px 3px 12px 0px rgba(0, 0, 0, 0.25);
}

.OwnBusinessTitle1 {
  flex: 1 0 0;
  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}

.OwnBusinessCon5R {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  overflow: hidden;
  justify-content: center;

  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.8) inset,
    0px 0px 12px 0px rgba(0, 0, 0, 0.5);
}
.OwnBusinessCon5 {
  display: flex;
  width: 100%;

  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.8) inset,
    0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  justify-content: center;

  gap: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.OwnBusinessCon5A {
  width: 100%;
  /* padding: 0px 12px; */
  box-sizing: border-box;
}

.OwnBusinessCon6 {
  align-self: stretch;
  /* height: 185px; */
  background: linear-gradient(
      179deg,
      rgba(0, 0, 0, 0) 12%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 75%);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  z-index: 0;
}

.OwnBusinessCon7 {
  align-self: stretch;
  padding: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
}

.OwnBusinessCon9 {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 25%;
  border: 2px solid #888;
  box-sizing: border-box;
  overflow: hidden;
}
.OwnBusinessCon9Img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  background: lightgray 50% / cover no-repeat;
}

.OwnBusinessCon10 {
  flex: 1 1 0;
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.OwnBusinessCon11 {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  gap: 12px;
}

.TratoresKiotyHopelchN {
  align-self: stretch;
  color: #ff3c00;
  font-size: 18px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.18px;
  word-wrap: break-word;
}
.TratoresKiotyHopelchNDescription {
  align-self: stretch;
  color: #fff;
  font-size: 10px;
  font-family: Inter;
  font-weight: 300;
  letter-spacing: 0.08px;
  word-wrap: break-word;
}

.OwnBusinessCon14 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.KVisitasLtimos7Dias {
  align-self: stretch;
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.12px;
  word-wrap: break-word;
}

/*  */

.OwnBusinessButtonsActionOnBusiness {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--app-white, #fff);
}
.OwnBusinessButtonsActionOnBusiness img {
  height: 22px;
}

.OwnBusinessButtonsActionOnBusinessOpen {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-top: 50px;

  /* background: var(--app-white, #fff); */
}
.OwnBusinessButtonsActionOnBusinessOpen img {
  height: 32px;
}

/* Start Create */
.OwnBusinessCon19 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.OwnBusinessCon20 {
  align-self: stretch;
  /* height: 144px; */
  margin-left: 12px;
  margin-right: 12px;
  padding: 12px;
  background: white;
  border-radius: 12px;
  border: 1px #2b2b2b solid;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: flex;
}

.OwnBusinessConMessage {
  align-self: stretch;
  text-align: center;
  color: var(--app-chacoal-dark);
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}

.OwnBusinessCon22 {
  width: 100%;
  height: 72px;
  padding-top: 12px;
  padding-bottom: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.OwnBusinessConButtonCreateBusinessPage {
  /* height: 48px; */
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--app-green);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  color: var(--app-white);
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.4px;
  word-wrap: break-word;
}

.OwnBusinessCon21 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.OwnBusinessConButtonWhatIsBusinessPage {
  width: 100%;
  text-align: center;
  color: var(--app-green);
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
  text-decoration: underline;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
/* End Create */

/* Start Listings */
.OwnBusinessListingsCon1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 24px;
}

.OwnBusinessListingsCon2 {
  display: flex;
  width: 100%;
  padding: 3px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  box-sizing: border-box;
}

.OwnBusinessListingsCon3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.OwnBusinessListingsTitle {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
}

.OwnBusinessListingsSeeAll {
  color: #797979;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.OwnBusinessListingsCon4 {
  display: flex;
  min-height: 100px;
  padding: 0px 3px;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  overflow-x: scroll;
}

/* Listing */
.OwnBusinessListingCon1 {
  position: relative;
  min-width: 120px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
}

.OwnBusinessListingCon2 {
  display: flex;
  height: 64px;
  width: 100%;
  background: white;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.OwnBusinessListingCon2Image {
  height: 100px;
  width: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
}

.OwnBusinessListingButtonMenu {
  position: absolute;
  right: 2px;
  top: 2px;
  display: flex;
  width: 48px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: rgba(43, 43, 43, 0.8);
  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.25);
}

.OwnBusinessListingCon3 {
  display: flex;
  width: 100%;
  min-height: 80px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  background: var(--Item-backg-secondary, #e8e8e8);
  box-sizing: border-box;
}

.OwnBusinessListingCon4 {
  display: flex;
  padding: 2px 6px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--app-gray-medium, #6d6d6d);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.OwnBusinessListingCon6 {
  width: 100%;
  display: flex;
  padding: 0px 6px;
  gap: 6px;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  bottom: 24px;
  box-sizing: border-box;
}

.OwnBusinessListingButtonAction {
  display: flex;
  width: 100%;
  padding: 6px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 0.5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
}
.OwnBusinessListingButtonActionPayNowCon {
  width: 100%;
  padding: 0px 6px;
  box-sizing: border-box;
}
.OwnBusinessListingButtonActionPayNow {
  display: flex;
  padding: 2px 6px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  background: var(--app-gold-yellow, #f4c542);
  box-sizing: border-box;

  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OwnBusinessListingButtonActionSelect {
  display: flex;
  padding: 2px 6px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  background: var(--app-white, #fff);
  border: solid 1px var(--app-gold-yellow, #f4c542);
  box-sizing: border-box;

  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.OwnBusinessListingButtonActionSelectSelected {
  display: flex;
  padding: 2px 6px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  background: var(--app-gold-yellow, #f4c542);
  box-sizing: border-box;

  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.OwnBusinessListingCon7 {
  display: flex;
  padding: 3px 6px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.OwnBusinessListingCon8 {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.OwnBusinessListingCon9 {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.OwnBusinessConBusinessMessageConSubscriptionButtonCon {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
}
.OwnBusinessConBusinessMessageConSubscriptionButton {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.OwnBusinessConBusinessMessageConSubscriptionButtonText {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}

/* TODO Subscription plan start here */
.SubBusPlanCon1A {
  display: flex;
  padding: 24px 12px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}
.SubBusPlanCon1 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
  border-radius: 12px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25) inset,
    0px 4px 12px 2px rgba(0, 0, 0, 0.5);
}
.SubBusPlanCon2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.SubBusPlanCon3 {
  display: flex;
  /* height: 48px; */
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 17px;
  background: var(--app-charcoal-dark, #2b2b2b);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
}

.SubBusPlanCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
}
.SubBusPlanCon5 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;
}
.SubBusPlanCon6 {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.SubBusPlanCon6B {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid var(--app-gray-soft, #e0e0e0);
}
.SubBusPlanConText1 {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanConText1B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SubBusPlanConText2 {
  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SubBusPlanCon7 {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid var(--app-gray-soft, #e0e0e0);
}
.SubBusPlanCon7B {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.SubBusPlanConText3 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SubBusPlanConText4 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SubBusPlanConText5 {
  color: var(--Danger-color-red, #f00);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SubBusPlanCon8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--app-white, #fff);
  overflow: hidden;
}
.SubBusPlanCon9 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 0px 6px 0px;
  background: #fff;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.SubBusPlanConText6 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanConText6B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanCon10 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.SubBusPlanConText7 {
  align-self: stretch;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SubBusPlanCon11 {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
}
.SubBusPlanCon12 {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.SubBusPlanCon12Active {
  border-radius: 6px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.SubBusPlanCon12NoneActive {
  border-radius: 6px;
  border: 0.5px solid var(--app-gray-soft, #e0e0e0);
}
.SubBusPlanCon12ActivePro {
  border-radius: 6px;
  background: var(
    --Mercado-MAI-pro,
    linear-gradient(0deg, #f80 0%, #e2ff04 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.SubBusPlanConText8 {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanConText9 {
  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanConText10 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SubBusPlanConText10B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SubBusPlanCon13 {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.SubBusPlanCon14 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px;
}
.SubBusPlanConText11 {
  flex: 1 0 0;
  color: var(--Success-color-green, #33b546);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanCon15 {
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
}
.SubBusPlanCon16 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SubBusPlanConText12 {
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SubBusPlanCon17 {
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--app-gray-soft, #e0e0e0);
}
.SubBusPlanCon18 {
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  width: 40px;
  gap: 10px;
  border-bottom: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
}
.SubBusPlanConText13 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SubBusPlanCon19 {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--app-white, #fff);
}
.SubBusPlanCon20 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.SubBusPlanCon21 {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.SubBusPlanConText14 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
.SubBusPlanConText15 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SubBusPlanConText16 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanConText14B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
.SubBusPlanConText15B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SubBusPlanConText16B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanCon22 {
  display: flex;
  padding: 6px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Success-color-green, #33b546);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5) inset,
    0px 2px 6px 1px rgba(0, 0, 0, 0.25);
}
.SubBusPlanCon22B {
  display: flex;
  padding: 6px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border-radius: 6px;
  background: var(--Action-color-Pro, #f8a401);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5) inset,
    0px 2px 6px 1px rgba(0, 0, 0, 0.25);
}
.SubBusPlanConText17 {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mercadoMAIBackground {
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
}
.mercadoMAIProBackground {
  background: var(
    --MAI-Pro-linear,
    linear-gradient(0deg, #f80 0%, #e2ff04 100%)
  );
}

.SubBusPlanCurrPlanCon1 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.SubBusPlanCurrPlanText1 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanCurrPlanCon2 {
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;
}
.SubBusPlanCurrPlanCon3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.SubBusPlanCurrPlanText2 {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SubBusPlanCurrPlanText3 {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textColorOrangeAction {
  color: var(--Action-color-Pro, #f8a401);
}
.textColorgreenAction {
  color: var(--Success-color-green, #33b546);
}

.SubBusPlanCurrPlanCon4 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.SubBusPlanCurrPlanText4 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
