.OwnBusinessCon12 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  display: flex;
}

.OwnBusinessCon13 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
}

.OwnBusinessCon15 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  height: 15px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.OwnBusinessConBusinessMessageCon {
  box-sizing: border-box;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.OwnBusinessConBusinessListingsCon {
  background: var(--app-beige, #f0e6d2);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 6px 0;
  display: flex;
}

.OwnBusinessCon1 {
  box-sizing: border-box;
  background: linear-gradient(#3a7d44 0%, #3a7d441a 13.49%, #3a7d4400 13.5%);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding-bottom: 32px;
  display: inline-flex;
  overflow: hidden scroll;
}

.OwnBusinessCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  gap: 10px;
  padding: 12px 12px 0;
  display: flex;
}

.OwnBusinessButtonCreateListing {
  background: var(--app-gold-yellow, #f4c542);
  color: #2b2b2b;
  letter-spacing: .4px;
  word-wrap: break-word;
  border-radius: 12px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 24px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.OwnBusinessCon16 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.OwnBusinessCon17 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.OwnBusinessCon18 {
  box-sizing: border-box;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-flex;
}

.OwnBusinessTitle2 {
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  color: var(--app-green, #3a7d44);
  text-align: center;
  letter-spacing: .2px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.OwnBusinessButtonEditLists {
  background: var(--app-orange, #ff7043);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
}

.OwnBusinessButtonEditListsText {
  color: var(--app-white);
  letter-spacing: .12px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
}

.OwnBusinessCon3 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
  display: inline-flex;
}

.OwnBusinessCon4B {
  box-sizing: border-box;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 0 12px;
  display: flex;
}

.OwnBusinessCon4 {
  border: .5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-beige, #f0e6d2);
  border-radius: 5px;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 2px 3px 12px #00000040;
}

.OwnBusinessTitle1 {
  color: var(--app-orange, #ff7043);
  text-align: center;
  letter-spacing: .16px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.OwnBusinessCon5R {
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  overflow: hidden;
  box-shadow: inset 0 4px 12px #000c, 0 0 12px #00000080;
}

.OwnBusinessCon5 {
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 4px 12px #000c, 0 0 12px #00000080;
}

.OwnBusinessCon5A {
  box-sizing: border-box;
  width: 100%;
}

.OwnBusinessCon6 {
  z-index: 0;
  background: linear-gradient(179deg, #0000 12%, #000000b3 100%), linear-gradient(#000000b3 0%, #0000 75%);
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.OwnBusinessCon7 {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  display: inline-flex;
}

.OwnBusinessCon9 {
  box-sizing: border-box;
  border: 2px solid #888;
  border-radius: 25%;
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
}

.OwnBusinessCon9Img {
  object-fit: cover;
  background: #d3d3d3 50% / cover no-repeat;
  width: 80px;
  height: 80px;
}

.OwnBusinessCon10 {
  flex: 1 1 0;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.OwnBusinessCon11 {
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
}

.TratoresKiotyHopelchN {
  color: #ff3c00;
  letter-spacing: .18px;
  word-wrap: break-word;
  align-self: stretch;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
}

.TratoresKiotyHopelchNDescription {
  color: #fff;
  letter-spacing: .08px;
  word-wrap: break-word;
  align-self: stretch;
  font-family: Inter;
  font-size: 10px;
  font-weight: 300;
}

.OwnBusinessCon14 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.KVisitasLtimos7Dias {
  color: #fff;
  letter-spacing: .12px;
  word-wrap: break-word;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
}

.OwnBusinessButtonsActionOnBusiness {
  background: var(--app-white, #fff);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 24px;
  height: 24px;
  padding: 6px 12px;
  display: flex;
}

.OwnBusinessButtonsActionOnBusiness img {
  height: 22px;
}

.OwnBusinessButtonsActionOnBusinessOpen {
  border-top: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 24px;
  height: 24px;
  padding: 6px 12px;
  display: flex;
}

.OwnBusinessButtonsActionOnBusinessOpen img {
  height: 32px;
}

.OwnBusinessCon19 {
  flex-wrap: wrap;
  place-content: flex-start center;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
}

.OwnBusinessCon20 {
  background: #fff;
  border: 1px solid #2b2b2b;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 12px;
  display: flex;
}

.OwnBusinessConMessage {
  text-align: center;
  color: var(--app-chacoal-dark);
  letter-spacing: .2px;
  word-wrap: break-word;
  align-self: stretch;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
}

.OwnBusinessCon22 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 72px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: inline-flex;
}

.OwnBusinessConButtonCreateBusinessPage {
  background: var(--app-green);
  color: var(--app-white);
  letter-spacing: .4px;
  word-wrap: break-word;
  border-radius: 17px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  display: inline-flex;
  overflow: hidden;
  box-shadow: 0 4px 4px #00000040;
}

.OwnBusinessCon21 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: inline-flex;
}

.OwnBusinessConButtonWhatIsBusinessPage {
  text-align: center;
  width: 100%;
  color: var(--app-green);
  letter-spacing: .2px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  text-decoration: underline;
}

.OwnBusinessListingsCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  display: flex;
}

.OwnBusinessListingsCon2 {
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  padding: 3px 12px;
  display: flex;
}

.OwnBusinessListingsCon3 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.OwnBusinessListingsTitle {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .16px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.OwnBusinessListingsSeeAll {
  color: #797979;
  letter-spacing: .1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}

.OwnBusinessListingsCon4 {
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  min-height: 100px;
  padding: 0 3px;
  display: flex;
  overflow-x: scroll;
}

.OwnBusinessListingCon1 {
  box-sizing: border-box;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
  max-width: 200px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.OwnBusinessListingCon2 {
  background: #fff;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 64px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.OwnBusinessListingCon2Image {
  object-fit: cover;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100px;
}

.OwnBusinessListingButtonMenu {
  background: #2b2b2bcc;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 48px;
  height: 36px;
  display: flex;
  position: absolute;
  top: 2px;
  right: 2px;
  box-shadow: 0 0 3px 1px #ffffff40;
}

.OwnBusinessListingCon3 {
  background: var(--Item-backg-secondary, #e8e8e8);
  box-sizing: border-box;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 80px;
  display: flex;
}

.OwnBusinessListingCon4 {
  background: var(--app-gray-medium, #6d6d6d);
  color: var(--app-white, #fff);
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.OwnBusinessListingCon6 {
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  padding: 0 6px;
  display: flex;
  position: absolute;
  bottom: 24px;
}

.OwnBusinessListingButtonAction {
  border: .5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 6px 2px;
  display: flex;
}

.OwnBusinessListingButtonActionPayNowCon {
  box-sizing: border-box;
  width: 100%;
  padding: 0 6px;
}

.OwnBusinessListingButtonActionPayNow {
  background: var(--app-gold-yellow, #f4c542);
  box-sizing: border-box;
  color: #000;
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.OwnBusinessListingButtonActionSelect {
  background: var(--app-white, #fff);
  border: solid 1px var(--app-gold-yellow, #f4c542);
  box-sizing: border-box;
  color: #000;
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.OwnBusinessListingButtonActionSelectSelected {
  background: var(--app-gold-yellow, #f4c542);
  box-sizing: border-box;
  color: #000;
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.OwnBusinessListingCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 3px 6px;
  display: flex;
}

.OwnBusinessListingCon8, .OwnBusinessListingCon9 {
  color: var(--app-gray-medium, #6d6d6d);
  align-items: center;
  gap: 2px;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.OwnBusinessConBusinessMessageConSubscriptionButtonCon {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  gap: 10px;
  padding: 0 12px;
  display: flex;
}

.OwnBusinessConBusinessMessageConSubscriptionButton {
  background: var(--app-white, #fff);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.OwnBusinessConBusinessMessageConSubscriptionButtonText {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .24px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanCon1A {
  box-sizing: border-box;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  height: 100%;
  padding: 24px 12px;
  display: flex;
  overflow: hidden scroll;
}

.SubBusPlanCon1 {
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  gap: 32px;
  padding: 12px;
  display: flex;
  box-shadow: inset 0 0 12px #00000040, 0 4px 12px 2px #00000080;
}

.SubBusPlanCon2 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.SubBusPlanCon3 {
  background: var(--app-charcoal-dark, #2b2b2b);
  color: var(--app-white, #fff);
  letter-spacing: .32px;
  border-radius: 17px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.SubBusPlanCon4 {
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  display: flex;
}

.SubBusPlanCon5 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 6px 12px;
  display: flex;
  box-shadow: inset 0 0 6px #00000040;
}

.SubBusPlanCon6 {
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.SubBusPlanCon6B {
  border: .5px solid var(--app-gray-soft, #e0e0e0);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.SubBusPlanConText1 {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanConText1B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SubBusPlanConText2 {
  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SubBusPlanCon7 {
  border: .5px solid var(--app-gray-soft, #e0e0e0);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.SubBusPlanCon7B {
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.SubBusPlanConText3 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SubBusPlanConText4 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SubBusPlanConText5 {
  color: var(--Danger-color-red, red);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SubBusPlanCon8 {
  border: 1px solid var(--app-white, #fff);
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
  overflow: hidden;
}

.SubBusPlanCon9 {
  background: #fff;
  border-radius: 0 0 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 2px 4px 4px #00000040;
}

.SubBusPlanConText6 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanConText6B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanCon10 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 12px;
  display: flex;
}

.SubBusPlanConText7 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  align-self: stretch;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SubBusPlanCon11 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-wrap: wrap;
  place-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px;
  display: flex;
  box-shadow: 0 2px 6px 1px #00000040;
}

.SubBusPlanCon12 {
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.SubBusPlanCon12Active {
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 6px;
  box-shadow: 0 4px 4px #00000040;
}

.SubBusPlanCon12NoneActive {
  border: .5px solid var(--app-gray-soft, #e0e0e0);
  border-radius: 6px;
}

.SubBusPlanCon12ActivePro {
  background: var(--Mercado-MAI-pro, linear-gradient(0deg, #f80 0%, #e2ff04 100%));
  border-radius: 6px;
  box-shadow: 0 4px 4px #00000040;
}

.SubBusPlanConText8 {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanConText9 {
  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanConText10 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SubBusPlanConText10B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SubBusPlanCon13 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.SubBusPlanCon14 {
  border-radius: 6px;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.SubBusPlanConText11 {
  color: var(--Success-color-green, #33b546);
  flex: 1 0 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanCon15 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px;
  display: flex;
  box-shadow: 0 2px 6px 1px #00000040;
}

.SubBusPlanCon16 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.SubBusPlanConText12 {
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SubBusPlanCon17 {
  border: 1px solid var(--app-gray-soft, #e0e0e0);
  border-radius: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 6px;
  display: flex;
}

.SubBusPlanCon18 {
  border-bottom: .5px solid var(--app-charcoal-dark, #2b2b2b);
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 40px;
  padding: 0 12px;
  display: flex;
}

.SubBusPlanConText13 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SubBusPlanCon19 {
  background: var(--app-white, #fff);
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  gap: 32px;
  padding: 6px 12px;
  display: flex;
}

.SubBusPlanCon20 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.SubBusPlanCon21 {
  align-items: flex-end;
  gap: 10px;
  display: flex;
}

.SubBusPlanConText14 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  text-shadow: 2px 2px 4px #00000040;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanConText15 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SubBusPlanConText16 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  text-shadow: 2px 2px 4px #00000040;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanConText14B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  text-shadow: 2px 2px 4px #00000040;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanConText15B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SubBusPlanConText16B {
  color: var(--Action-color-Pro, #f8a401);
  text-align: center;
  text-shadow: 2px 2px 4px #00000040;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanCon22 {
  background: var(--Success-color-green, #33b546);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 32px;
  display: flex;
  box-shadow: inset 0 0 6px #00000080, 0 2px 6px 1px #00000040;
}

.SubBusPlanCon22B {
  background: var(--Action-color-Pro, #f8a401);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 32px;
  display: flex;
  box-shadow: inset 0 0 6px #00000080, 0 2px 6px 1px #00000040;
}

.SubBusPlanConText17 {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mercadoMAIBackground {
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
}

.mercadoMAIProBackground {
  background: var(--MAI-Pro-linear, linear-gradient(0deg, #f80 0%, #e2ff04 100%));
}

.SubBusPlanCurrPlanCon1 {
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.SubBusPlanCurrPlanText1 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanCurrPlanCon2 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 6px;
  display: flex;
  box-shadow: inset 0 0 6px #00000040;
}

.SubBusPlanCurrPlanCon3 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.SubBusPlanCurrPlanText2 {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubBusPlanCurrPlanText3 {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textColorOrangeAction {
  color: var(--Action-color-Pro, #f8a401);
}

.textColorgreenAction {
  color: var(--Success-color-green, #33b546);
}

.SubBusPlanCurrPlanCon4 {
  background: var(--app-white, #fff);
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.SubBusPlanCurrPlanText4 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/*# sourceMappingURL=index.289514c1.css.map */
